import { Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Badge, Stack } from "@mui/material";
import { useAgentPreferences } from "@src/appV2/Agents/api/useAgentPreferences";
import { CbhFeatureFlag, useCbhFlag } from "@src/appV2/FeatureFlags";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";

import { useFetchPaginatedShiftBlocks } from "../api/useFetchPaginatedShiftBlocks";

export function ShiftBlockTabTitle() {
  const worker = useDefinedWorker();
  const { filters } = useAgentPreferences();
  const shiftBlockBookingFlag = useCbhFlag(CbhFeatureFlag.ROLLOUT_SHIFT_BLOCK_BOOKING, {
    defaultValue: {
      isBlockBookingEnabled: false,
    },
  });
  const { data: shiftBlocksData } = useFetchPaginatedShiftBlocks(
    {
      filter: {
        agentId: worker.userId,
        qualification: filters?.license,
      },
    },
    {
      enabled:
        isDefined(worker) &&
        isDefined(filters) &&
        isDefined(filters.license) &&
        shiftBlockBookingFlag.isBlockBookingEnabled,
    }
  );
  const totalShiftBlocks = shiftBlocksData?.pages[0]?.data.length ?? 0;
  return (
    <Stack direction="row" alignItems="center" spacing={2}>
      <Text>Blocks</Text>

      <Badge
        color="primary"
        badgeContent={totalShiftBlocks}
        sx={{
          "& .MuiBadge-badge": {
            backgroundColor: "transparent",
            color: (theme) => theme.palette.text.primary,
            border: (theme) => `1px solid ${theme.palette.divider}`,
          },
        }}
      />
    </Stack>
  );
}
